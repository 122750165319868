<template>
    <b-container>
        <ResetPasswordRequest class="reset-password-request-form" />
    </b-container>
</template>

<script>
    import ResetPasswordRequest from '@/components/ResetPasswordRequest.vue'

    export default {
        name: 'reset-password-request',
        components: {
            ResetPasswordRequest
        },
        mounted: function() {
            this.$ebus.$emit("title:set", this.$t("menu.resetPassword"))
        },
        destroyed: function() {
            this.$ebus.$emit("title:reset")
        }
    }
</script>

<style scoped lang="scss">   
    @import 'src/scss/main';

    .reset-password-request-form {
        margin-top: $container-top-margin;
    }
</style>